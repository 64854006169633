<div class="login-wrapper">
    <form class="login">
        <div class="logo">
        <a href="https://www.zahntechnik-sauerland.de/" target="_blank">
        <img src="/assets/logo_white.svg" alt="Dentaltechnik Hamm">
        </a>
        </div>
        <section class="title">
            <h3 class="welcome">Willkommen bei</h3>
            Dental-Technik Hamm
            <h5 class="hint">Benutzen Sie Ihre Benutzerdaten um sich anzumelden:</h5>
        </section>
        <div class="authBlock" class="formGroup" class="login-group">
            <clr-input-container>
                <input type="text" class="formControl" clrInput placeholder="E-Mail" name="input" #userName required />
            </clr-input-container>
            <clr-password-container>
                <input type="password" class="formControl" clrPassword placeholder="Password" name="password" #userPassword required />
            </clr-password-container>
            <button (click)="authService.SignIn(userName.value, userPassword.value)" type="submit" class="btn btn-primary">ANMELDEN</button>
            <a routerLink="/register-user" class="signup">Konto erstellen</a>
            <a routerLink="/forgot-password" class="signup">Passwort vergessen?</a>
        </div>
      <p>Version: 1.0.1</p>
    </form>
</div>


<clr-main-container>
  <app-header></app-header>
  <div class="content-container">
    <main class="content-area">
      <div *ngIf="user != null" class="clr-row">
        <div class="clr-col-1">
        </div>

        <div class="clr-col-11">
          <h1>Intraoral 3D Scans</h1>
          <button (click)="openPatientModal()" class="btn btn-primary">Neuen Patient anlegen</button>
          <clr-tabs>
            <clr-tab>
              <button clrTabLink id="link1">Aktuelle Aufträge</button>
              <clr-tab-content id="content1" *clrIfActive="true">
                <app-uploadtable
                  [uploads]="userUploadsFirstTwoWeeks">
                </app-uploadtable>
              </clr-tab-content>
            </clr-tab>
            <clr-tab>
              <button clrTabLink>Letzten 2 Wochen</button>
              <clr-tab-content *clrIfActive="false">
                <app-uploadtable
                  [uploads]="userUploadsTwoWeeks">
                </app-uploadtable>
              </clr-tab-content>
            </clr-tab>
            <clr-tab>
              <button clrTabLink>Älter als 4 Wochen</button>
              <clr-tab-content *clrIfActive="false">
                <app-uploadtable
                  [uploads]="userUploadsFourWeeks">
                </app-uploadtable>
              </clr-tab-content>
            </clr-tab>
            <clr-tab>
              <button clrTabLink>Archiv</button>
              <clr-tab-content *clrIfActive="false">
                <app-uploadtable
                  [uploads]="userUploadsArchive">
                </app-uploadtable>
              </clr-tab-content>
            </clr-tab>
          </clr-tabs>

        </div>
      </div>

      <div *ngIf="authService.singleuser as singleuser">
        <div *ngIf="downloadURL as url">
          <clr-modal [(clrModalOpen)]="isOpen">
            <h3 class="modal-title">Upload erfolgreich!</h3>
            <div class="modal-body">
              <p>Vielen Dank für Ihr Vertrauen!</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" (click)="isOpen = !isOpen">Ok</button>
            </div>
          </clr-modal>
        </div>

      </div>
    </main>
  </div>
</clr-main-container>



<clr-modal [(clrModalOpen)]="patientModal">
  <h3 class="modal-title">Patienten anlegen:</h3>
  <div class="modal-body">
    <div>
      <form clrForm [formGroup]="form">
        <h4 >Patientenvorname</h4>
        <input clrInput formControlName="patname" clrInput placeholder="Vorname" class="formControl" name="name"/>
        <h4>Patientennachname</h4>
        <input clrInput formControlName="patlastname" clrInput placeholder="Nachname" class="formControl"
               name="name"/>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="createPatient()" class="btn btn-sm btn-info-outline">Patient anlegen</button>
  </div>
</clr-modal>


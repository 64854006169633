        <section class="sidenav-content">
                <a href="..." class="nav-link active">
                    Impressum
                </a>
                <a href="..." class="nav-link">
                    Datenschutz
                </a>
                <a href="..." class="nav-link">
                        Kontakt
                    </a>
            </section>
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Userdetails } from 'src/app/services/user_details';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  formGroup: FormGroup;
  emailFormControl: FormControl;
  passwordlFormControl: FormControl;
  companynamelFormControl: FormControl;
  postcodeFormControl: FormControl;
  citylFormControl: FormControl;
  companytypeFormControl: FormControl;
  technicanFormControl: FormControl;
  phoneFormControl: FormControl;
  AGBFormControl: FormControl;
  streetFormControl: FormControl;


  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
  ) {
    this.emailFormControl = new FormControl('', Validators.required);
    this.passwordlFormControl = new FormControl('', Validators.required);
    this.companynamelFormControl = new FormControl('', Validators.required);
    this.postcodeFormControl = new FormControl('', Validators.required);
    this.citylFormControl = new FormControl('', Validators.required);
    this.companytypeFormControl = new FormControl('', Validators.required);
    this.technicanFormControl = new FormControl('', Validators.required);
    this.phoneFormControl = new FormControl('', Validators.required);
    this.AGBFormControl = new FormControl('', Validators.required);
    this.streetFormControl = new FormControl('', Validators.required);

    this.formGroup = this.formBuilder.group({
      email: this.emailFormControl,
      password: this.passwordlFormControl,
      company: this.companynamelFormControl,
      city: this.citylFormControl,
      postcode: this.postcodeFormControl,
      companytype: this.companytypeFormControl,
      street: this.streetFormControl,
      technican: this.technicanFormControl,
      phone: this.phoneFormControl,
      AGB: this.AGBFormControl
    });
  }

  ngOnInit() {
  }

  submit(): void {
    if (!this.formGroup.valid) {
    return;
    }

    const email: string = this.formGroup.get('email').value;
    const password: string = this.formGroup.get('password').value;
    const companyname: string = this.formGroup.get('company').value;
    const postcode: string = this.formGroup.get('postcode').value;
    const city: string = this.formGroup.get('city').value;
    const companytype: string = this.formGroup.get('companytype').value;
    const street: string = this.formGroup.get('street').value;
    const technican: string = this.formGroup.get('technican').value;
    const phone: string = this.formGroup.get('phone').value;
    const AGB: string = this.formGroup.get('AGB').value;

    const userdetails: Userdetails = new Userdetails();
    userdetails.city = city;
    userdetails.companyname = companyname;
    userdetails.postcode = postcode;
    userdetails.companytype = companytype;
    userdetails.phone = phone;
    userdetails.street = street;
    userdetails.AGB = AGB;
    userdetails.technican = technican;

    this.authService.SignUp(email, password, userdetails);
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData, HashLocationStrategy, LocationStrategy } from '@angular/common';
import localeDe from '@angular/common/locales/de';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '../environments/environment';

import { AuthService } from 'src/app/services/auth.service';
import { DropzoneDirective } from './uploads/dropzone.directive';
import { UploaderComponent } from './uploads/uploader/uploader.component';
import { UploadTaskComponent } from './uploads/upload-task/upload-task.component';
import { AngularFireStorageModule, AngularFireStorage } from '@angular/fire/storage';

import 'firebase/firestore';
import { UploadformComponent } from './uploads/uploadform/uploadform.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidenavComponent } from './layout/sidenav/sidenav.component';
import { OrdersListComponent } from './Orders/orders-list/orders-list.component';
import { NewuploadformComponent } from './uploads/newuploadform/newuploadform.component';
import { FileSizePipe } from './file-size.pipe';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ArchivComponent } from './archiv/archiv.component';
import { IosuploadComponent } from './iosupload/iosupload.component';
import { UploadtableComponent } from './uploadtable/uploadtable.component';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    DashboardComponent,
    VerifyEmailComponent,
    ForgotPasswordComponent,
    DropzoneDirective,
    UploaderComponent,
    UploadTaskComponent,
    UploadformComponent,
    HeaderComponent,
    SidenavComponent,
    OrdersListComponent,
    NewuploadformComponent,
    FileSizePipe,
    ArchivComponent,
    IosuploadComponent,
    UploadtableComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    ReactiveFormsModule,
    FormsModule,
    ClarityModule,
    BrowserAnimationsModule,
    AngularFireStorageModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [AngularFirestore, AuthService, { provide: LOCALE_ID, useValue: 'de' },{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe);
  }
}

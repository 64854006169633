<clr-main-container>
    <app-header></app-header>
    <div class="content-container">
        <main class="content-area">
                <div class="clr-row">
                        <div class="clr-col-1">
                        </div>
                        <div class="clr-col-10">
            <h1>Neue Aufträge:</h1>
                <div class="clr-row">
                        <div *ngFor="let data of order | async" class="clr-col-lg-3 clr-col-md-6 clr-col-12">
                            <div class="card">
                                <div class="card-header">
                                        <div class="alert alert-warning" role="alert">
                                                <div class="alert-items">
                                                    <div class="alert-item static">
                                                        <div class="alert-icon-wrapper">
                                                            <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
                                                        </div>
                                                        <div class="alert-text">
                                                        Der Auftrag wartet auf die Freigabe!
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        {{ data.description }}
                                </div>
                                <div class="card-block">
                                    <div class="card-text">
                                            <strong>Patientename:</strong>
                                            <br>
                                            <span>{{ data.patname }}{{ data.patlastname }}</span>
                                            <br>
                                            <strong>Dateiname:</strong>
                                            <br>
                                            <span>{{ data.path }}</span>
                                            <br>
                                            <strong>Einheiten: </strong>
                                            <br>
                                            <span>{{ data.ordernumber }}</span>
                                            <br>
                                    </div>
                                </div>
                                <div class="card-footer">
                                        <clr-accordion>
                                                <clr-accordion-panel>
                                                  <clr-accordion-title>Mehr Infos</clr-accordion-title>
                                                  <clr-accordion-content *clrIfExpanded>
                                                        <strong>Material: </strong>
                                                        <br>
                                                        <span>{{ data.materialtype }}</span>
                                                        <br>
                                                        <strong>Materialmarke: </strong>
                                                        <br>
                                                        <span>{{ data.materialbrand }}</span>
                                                        <br>
                                                        <strong>Zahnfarbe: </strong>
                                                        <br>
                                                        <span>{{ data.teethcolor }}</span>
                                                        <br>
                                                        <strong>Auftragsdatum:</strong>
                                                        <br>
                                                        <span>{{ data.date | date:'medium' }}</span>
                                                        <br>
                                                        <strong>Lieferdatum:</strong>
                                                        <br>
                                                        <span>{{ data.deliverydate }}</span>
                                                        <br>
                                                        <strong>Was wurder gesendet?</strong>
                                                        <br>
                                                        <span>{{ data.whattosend }}</span>
                                                        <br>
                                                        <clr-signpost>
                                                                <button class="btn btn-link" 
                                                                        clrSignpostTrigger>
                                                                            <clr-icon shape="details"></clr-icon>
                                                                </button>
                                                                <clr-signpost-content [clrPosition]="'right-middle'" *clrIfOpen>
                                                                        {{ data.notes }}
                                                                </clr-signpost-content>
                                                            </clr-signpost>
                                                    </clr-accordion-content>
                                                </clr-accordion-panel>
                                              </clr-accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h1>Angenommen und in Bearbeitung:</h1>
                    <div class="clr-row">
                        <div *ngFor="let data of neworder | async" class="clr-col-lg-3 clr-col-md-6 clr-col-12">
                                                        <div class="card">
                                <div class="card-header">
                                        <div class="alert alert-app-level alert-info" role="alert">
                                                <div class="alert-items">
                                                    <div class="alert-item static">
                                                        <div class="alert-icon-wrapper">
                                                            <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
                                                        </div>
                                                        <div class="alert-text">
                                                        Der Auftrag ist in Bearbeitung!
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        {{ data.description }}
                                </div>
                                <div class="card-block">
                                    <div class="card-text">
                                            <strong>Patientename:</strong>
                                            <br>
                                            <span>{{ data.patname }}{{ data.patlastname }}</span>
                                            <br>
                                            <strong>Dateiname:</strong>
                                            <br>
                                            <span>{{ data.path }}</span>
                                            <br>
                                            <strong>Einheiten: </strong>
                                            <br>
                                            <span>{{ data.ordernumber }}</span>
                                            <br>
                                            <strong>Kommentar: </strong>
                                            <br>
                                            <span>{{ data.comment }}</span>
                                            <br>
                                    </div>
                                </div>
                                <div class="card-footer">
                                        <clr-accordion>
                                                <clr-accordion-panel>
                                                  <clr-accordion-title>Mehr Infos</clr-accordion-title>
                                                  <clr-accordion-content *clrIfExpanded>
                                                        <strong>Material: </strong>
                                                        <br>
                                                        <span>{{ data.materialtype }}</span>
                                                        <br>
                                                        <strong>Materialmarke: </strong>
                                                        <br>
                                                        <span>{{ data.materialbrand }}</span>
                                                        <br>
                                                        <strong>Zahnfarbe: </strong>
                                                        <br>
                                                        <span>{{ data.teethcolor }}</span>
                                                        <br>
                                                        <strong>Auftragsdatum:</strong>
                                                        <br>
                                                        <span>{{ data.date | date:'medium' }}</span>
                                                        <br>
                                                        <strong>Lieferdatum:</strong>
                                                        <br>
                                                        <span>{{ data.deliverydate }}</span>
                                                        <br>
                                                        <strong>Was wurder gesendet?</strong>
                                                        <br>
                                                        <span>{{ data.whattosend }}</span>
                                                        <br>
                                                        <clr-signpost>
                                                                <button class="btn btn-link" 
                                                                        clrSignpostTrigger>
                                                                            <clr-icon shape="details"></clr-icon>
                                                                </button>
                                                                <clr-signpost-content [clrPosition]="'right-middle'" *clrIfOpen>
                                                                        {{ data.notes }}
                                                                </clr-signpost-content>
                                                            </clr-signpost>
                                                    </clr-accordion-content>
                                                </clr-accordion-panel>
                                              </clr-accordion>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <h1>Abgeschlossen:</h1>
                    <div class="clr-row">

                        <div *ngFor="let data of finishorder | async" class="clr-col-lg-3 clr-col-md-6 clr-col-12">
                            <div class="card">
    <div class="card-header">
            <div class="alert alert-success" role="alert">
                    <div class="alert-items">
                        <div class="alert-item static">
                            <div class="alert-icon-wrapper">
                                <clr-icon class="alert-icon" shape="check-circle"></clr-icon>
                            </div>
                            <div class="alert-text">
                            Der Auftrag ist Abgeschlossen und wird am Folgetag bei Ihnen eintreffen!
                            </div>
                        </div>
                    </div>
                </div>
            {{ data.description }}
    </div>
    <div class="card-block">
        <div class="card-text">
                <strong>Patientename:</strong>
                <br>
                <span>{{ data.patname }}{{ data.patlastname }}</span>
                <br>
                <strong>Dateiname:</strong>
                <br>
                <span>{{ data.path }}</span>
                <br>
                <strong>Einheiten: </strong>
                <br>
                <span>{{ data.ordernumber }}</span>
                <br>
                <strong>Kommentar: </strong>
                <br>
                <span>{{ data.comment }}</span>
                <br>
        </div>
    </div>
    <div class="card-footer">
            <button (click)="updateOrder(data)" class="btn btn-primary btn-block">Auftrag in Archiv ablegen</button>
    </div>
</div>
</div>
                    </div>
                </div>
            </div>
                    
        </main>
    </div>
</clr-main-container>
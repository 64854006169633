<div class="login-wrapper">
    <form class="login">
        <section class="title">
            <h3 class="welcome">Sie haben Ihr Passwort vergessen?</h3>
            <br>
            <h5 class="hint">Bitte geben Sie Ihre E-Mailadresse ein um Ihr Passwort zurückzusetzen:</h5>
            <clr-input-container>
                <input type="text" class="formControl" clrInput placeholder="E-Mail" name="input" [(ngModel)]="email" required />
            </clr-input-container>
            <br>
        </section>
            <button (click)="authService.ForgotPassword(email)" type="submit" class="btn btn-primary">Passwort zurücksetzen</button>
            <br>
            <a routerLink="/sign-in" class="signup">zurück zur Anmeldung</a>
    </form>
</div>
  
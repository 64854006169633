<div class="login-wrapper">
    <form [formGroup]="formGroup" class="login">
        <section class="title">
            <h3 class="welcome">Willkommen bei der</h3>
            Registrierung
            <h5 class="hint">Wir freuen uns auf unsere Zusammenarbeit!</h5>
        </section>
        <div class="authBlock" class="formGroup" class="login-group">
            <select formControlName='companytype' class="formControl" clrSelect name="options" required>
                <option value="Dentallabor">Dentallabor</option>
                <option value="Zahnarzt">Zahnarzt</option>
                <option value="Fräszentrum">Fräszentrum</option>
                <option value="Patient">Patient</option>
              </select>
            <clr-input-container>
                <input formControlName='company' type="text" class="formControl" clrInput
                    placeholder="Firmenname" />
            </clr-input-container>
              <clr-input-container>
                <input formControlName='street' type="text" class="formControl" clrInput
                    placeholder="Straße" />
            </clr-input-container>
              <clr-input-container>
                <input formControlName='postcode' type="number" class="formControl" clrInput
                    placeholder="Postleitzahl" />
            </clr-input-container>
            <clr-input-container>
                <input formControlName='city' type="text" class="formControl" clrInput
                    placeholder="Stadt" />
            </clr-input-container>
            <br>
            <clr-input-container>
                <input formControlName='technican' type="text" class="formControl" clrInput
                    placeholder="Ansprechpartner" />
            </clr-input-container>
            <clr-input-container>
                <input formControlName='phone' type="tel" class="formControl" clrInput
                    placeholder="Telefonnummer" />
            </clr-input-container>
            <br>
            <clr-input-container>
                <input formControlName='email' type="email" class="formControl" clrInput placeholder="E-Mail" />
            </clr-input-container>
            <clr-password-container>
                <input formControlName='password' type="password" class="formControl" clrPassword
                    placeholder="Password" />
            </clr-password-container>
            <clr-checkbox-wrapper>
                <input formControlName='AGB' type="checkbox" value="true" clrCheckbox class="formControl" name="options" />
                <label>Ich habe die AGBs gelesen und stimme zu</label>
              </clr-checkbox-wrapper>
            <button [disabled]="!formGroup.valid" (click)="submit()" type="submit" class="btn btn-primary">REGISTRIEREN</button>
            <a routerLink="/sign-in" class="signup">zurück zur Anmeldung</a>
        </div> 
    </form>
</div>
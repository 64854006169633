import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Required components for which route services to be activated
import { SignInComponent } from 'src/app/components/sign-in/sign-in.component';
import { SignUpComponent } from 'src/app/components/sign-up/sign-up.component';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from 'src/app/components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from 'src/app/components/verify-email/verify-email.component';

// Import canActivate guard services
import { AuthGuard } from 'src/app/shared/guard/auth.guard';
import { SecureInnerPagesGuard } from 'src/app/shared/guard/secure-inner-pages.guard';
import { UploadformComponent } from './uploads/uploadform/uploadform.component';
import { OrdersListComponent } from './Orders/orders-list/orders-list.component';
import { NewuploadformComponent } from './uploads/newuploadform/newuploadform.component';
import { ArchivComponent } from './archiv/archiv.component';
import { IosuploadComponent } from './iosupload/iosupload.component';

// Include route guard in routes array
const routes: Routes = [
  { path: '', redirectTo: '/sign-in', pathMatch: 'full'},
  { path: 'sign-in', component: SignInComponent, canActivate: [SecureInnerPagesGuard]},
  { path: 'register-user', component: SignUpComponent, canActivate: [SecureInnerPagesGuard]},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [SecureInnerPagesGuard] },
  { path: 'verify-email-address', component: VerifyEmailComponent, canActivate: [SecureInnerPagesGuard] },
  { path: 'upload', component: UploadformComponent, canActivate: [AuthGuard] },
  { path: 'iosscan', component: IosuploadComponent, canActivate: [AuthGuard] },
  { path: 'orders', component: OrdersListComponent, canActivate: [AuthGuard] },
  { path: 'newupload', component: NewuploadformComponent, canActivate: [AuthGuard] },
  { path: 'archiv', component: ArchivComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }

<h3>Auftragsformular:</h3>
  <form clrForm >
    <clr-input-container>
      <label>Beschreibung</label>
      <input clrInput placeholder="Beschreibung"  name="name" />
      <clr-control-helper>z.B. NEM Brücke von 34-36</clr-control-helper>
    </clr-input-container>
    <clr-select-container>
        <label>Werkstoff</label>
        <select clrSelect name="options" required>
            <option value="one">CoCr</option>
            <option value="one">Titan</option>
            <option value="two">Zirkon trans</option>
            <option value="two">Zirkon opak</option>
            <option value="two">Zirkon weiß</option>
            <option value="two">Zirkon Multilayer</option>
            <option value="two">PMMA</option>
          </select>
    </clr-select-container>
      <clr-select-container>
          <label>Zahnfarbe</label>
          <select clrSelect name="options" required>
              <option value="one">keine</option>
              <option value="one">A1</option>
              <option value="two">A2</option>
              <option value="three">A3</option>
              <option value="three">A4</option>
              <option value="three">B1</option>
              <option value="three">B2</option>
              <option value="three">B3</option>
              <option value="three">B4</option>
              <option value="three">C1</option>
              <option value="three">C2</option>
              <option value="three">C3</option>
              <option value="three">C4</option>
              <option value="three">D1</option>
              <option value="three">D2</option>
              <option value="three">D3</option>
              <option value="three">D4</option>
            </select>
            <clr-control-helper>bei Metallen keine angeben</clr-control-helper>
      </clr-select-container>

      <clr-textarea-container>
          <label>Notizen</label>
          <textarea clrTextarea [(ngModel)]="description" name="description"></textarea>
        </clr-textarea-container>
        <br>
        <br>
            <label>Datei</label>
            <input class="file-input" type="file" (change)="onDrop($event.target.files)">
        <br>
        <br>
        <button  class="btn btn-primary">Absenden</button>
  </form>
<h3>Auftrags Upload:</h3>

<div *ngFor="let file of files">
  <app-upload-task [file]="file"></app-upload-task>
</div>

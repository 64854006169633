<clr-main-container>
        <app-header></app-header>
        <div class="content-container">
            <main class="content-area">
                    <div *ngIf="authService.singleuser as singleuser">
                            <h1>Willkommen:<strong> ANSPRECHPARTNER</strong></h1>
                    <app-uploader></app-uploader>
                </div>
            </main>
            <nav class="sidenav" [clr-nav-level]="2">
<app-sidenav></app-sidenav>
            </nav>
        </div>
    </clr-main-container>
    

       

     
<clr-main-container>
        <app-header></app-header>
        <div class="content-container">
            <main class="content-area">
            <h1>Archiv:</h1>
            <clr-datagrid>
                    <clr-dg-column>Beschreibung</clr-dg-column>
                    <clr-dg-column>Vorname</clr-dg-column>
                    <clr-dg-column>Nachname</clr-dg-column>
                    <clr-dg-column>Einheiten</clr-dg-column>
                    <clr-dg-column>Material</clr-dg-column>
                    <clr-dg-column>Material Marke</clr-dg-column>
                    <clr-dg-column>Zahnfarbe</clr-dg-column>
                    <clr-dg-column>Auftragsdatum</clr-dg-column>
                    <clr-dg-column>Lieferdatum</clr-dg-column>
                    <clr-dg-column>Datensatztyp</clr-dg-column>
                
                    <clr-dg-row *ngFor="let order of files">
                        <clr-dg-cell>{{ order.payload.doc.data().description }}</clr-dg-cell>
                        <clr-dg-cell>{{ order.payload.doc.data().patname }}</clr-dg-cell>
                        <clr-dg-cell>{{ order.payload.doc.data().patlastname }}</clr-dg-cell>
                        <clr-dg-cell>{{ order.payload.doc.data().ordernumber }}</clr-dg-cell>
                        <clr-dg-cell>{{ order.payload.doc.data().materialtype }}</clr-dg-cell>
                        <clr-dg-cell>{{ order.payload.doc.data().materialbrand }}</clr-dg-cell>
                        <clr-dg-cell>{{ order.payload.doc.data().teethcolor }}</clr-dg-cell>
                        <clr-dg-cell>{{ order.payload.doc.data().date | date:'medium' }}</clr-dg-cell>
                        <clr-dg-cell>{{ order.payload.doc.data().deliverydate }}</clr-dg-cell>
                        <clr-dg-cell>{{ order.payload.doc.data().whattosend }}</clr-dg-cell>
                    </clr-dg-row>
                </clr-datagrid>
            </main>
        </div>
</clr-main-container>

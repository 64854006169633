import {Component, OnInit, Input, ChangeDetectorRef} from '@angular/core';
import {AngularFireStorage, AngularFireUploadTask} from '@angular/fire/storage';
import {AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {AuthService} from 'src/app/services/auth.service';
import {FormControl, FormGroup, FormBuilder, Validator, Validators, ReactiveFormsModule} from '@angular/forms';
import {EmailService} from 'src/app/services/email.service';
import {AngularFireAuth} from '@angular/fire/auth';
import * as uuid from 'uuid';
import {IosUploadService} from "../ios-upload.service";


interface Materialandcolor {
  material: string;
}

@Component({
  selector: 'app-iosupload',
  templateUrl: './iosupload.component.html',
  styleUrls: ['./iosupload.component.scss']
})
export class IosuploadComponent implements OnInit {
  public get OrderStatus(): typeof OrderStatus {
    return OrderStatus;
  }

  userUploads = [];
  userUploadsFirstTwoWeeks = [];
  userUploadsTwoWeeks = [];
  userUploadsFourWeeks = [];
  userUploadsArchive = [];
  uploadModal = false;
  patientModal = false;
  currentPatient = null;

  user;




  form: FormGroup;
  uploadform: FormGroup;

  isOpen = true;


  formGroup: FormGroup;
  notesFormControl: FormControl;
  patnameFormControl: FormControl;
  patlastnameFormControl: FormControl;
  typeFormControl: FormControl;

// Main task
  task: AngularFireUploadTask;

// Progress monitoring
  percentage: Observable<number>;

  snapshot: Observable<any>;

// Download URL
  downloadURL: Observable<string>;

  selectedFiles: FileList;

  testUid: string;


  constructor(
    private storage: AngularFireStorage,
    private db: AngularFirestore,
    public authService: AuthService,
    private formBuilder: FormBuilder,
    public emailService: EmailService,
    public afAuth: AngularFireAuth,
    private iosService: IosUploadService,
  ) {
    this.patnameFormControl = new FormControl('', Validators.required);
    this.patlastnameFormControl = new FormControl('', Validators.required);


    this.form = this.formBuilder.group({
      patname: this.patnameFormControl,
      patlastname: this.patlastnameFormControl
    });




  }


  submit() {
    console.log('reactive form submit', this.form.value);
  }




  ngOnInit() {
    this.getUser();

    this.loadData()
  }

  async getUser() {
    this.testUid = await this.authService.getTestStringUid()
    this.user = await this.authService.getUser()
  }


  loadData() {
    this.db.collection('/iosScans', ref => {
      return ref.where('status', '<=', 2).orderBy('status').where('UserID', '==', this.authService.singleuser.uid).orderBy('onCreate', "desc");
    }).snapshotChanges()
      .subscribe(snapshots => {
        this.userUploads = snapshots.map(e => {
          return e.payload.doc.data();

        });

        this.userUploads = this.userUploads.sort((n1,n2) => {
          if (n1.age > n2.age) {
            return 1;
          }

          if (n1.onCreate > n2.onCreate) {
            return -1;
          }

          return 0;
        });

        const dateNow = Date.now();
        const twoWeeks = dateNow - 1209600000;
        const fourWeeks = dateNow - 2419200000;
        this.userUploadsFirstTwoWeeks = this.userUploads.filter(upload => (dateNow > upload.onCreate && twoWeeks < upload.onCreate));
        this.userUploadsTwoWeeks = this.userUploads.filter(upload => (twoWeeks > upload.onCreate && fourWeeks < upload.onCreate));
        this.userUploadsFourWeeks = this.userUploads.filter(upload => fourWeeks > upload.onCreate);
      })


    this.db.collection('/iosScans', ref => {
      return ref.where('UserID', '==', this.authService.singleuser.uid).where('status', '==', OrderStatus.ARCHIVE).orderBy('onCreate');
    })
      .snapshotChanges()
      .subscribe(snapshots => {
        this.userUploadsArchive = snapshots.map(e => {
          return e.payload.doc.data();

        });

      })
  }


openPatientModal() {
    this.form.reset();
    this.patientModal = !this.patientModal;
}


  async createPatient(): Promise<void> {
    this.patientModal = false;
    const name = this.form.get('patname').value;
    const lastName = this.form.get('patlastname').value;
    await this.iosService.createPatient(name, lastName);
    this.form.reset();

  }

  async deletePatient(patient): Promise<void> {
    await this.db.collection('iosScans').doc(patient.patientID).delete();
  }



  async removePatientUpload(patient, uploaditem): Promise<void> {
    const uploads = patient.payload.doc.data().uploads;

    const index = uploads.findIndex(item => item.onCreate == uploaditem);

    uploads.splice(index, 1);

    await this.db.collection('iosScans').doc(patient.patientID).set(
      {uploads},
      {merge: true}
    );
  }

  openUploadModel(patient): void {
    this.currentPatient = patient;
    this.uploadModal = true;
  }


// Determines if the upload task is active
  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }


}


export enum OrderStatus {
  NEW,
  PENDING,
  COMPLETE,
  ARCHIVE
}

export class Patient {
  UserID: string;
  dentist: string;
  onCreate: number;
  patientID: string;
  patientLastName: string;
  patientName: string;
  status: OrderStatus;
  uploads: Uploads[];
}


export class Uploads {
  dentistNotes: string;
  downloadDate: number;
  downloaded: boolean;
  filename: string;
  labNotes: string;
  onCreate: string;
  uploadType: string;
  url: string;
}

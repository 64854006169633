import {Component, Input, OnChanges} from '@angular/core';
import {IosUploadService} from "../ios-upload.service";
import {element} from "protractor";
import {finalize, tap} from "rxjs/operators";
import {AngularFireStorage, AngularFireUploadTask} from "@angular/fire/storage";
import {Observable} from "rxjs";
import {AngularFirestore} from "@angular/fire/firestore";
import {FormControl, FormGroup, FormBuilder, Validator, Validators, ReactiveFormsModule} from '@angular/forms';

@Component({
  selector: 'app-uploadtable',
  templateUrl: './uploadtable.component.html',
  styleUrls: ['./uploadtable.component.scss']
})
export class UploadtableComponent implements OnChanges {


  @Input() uploads; // tslint:disable-line: no-input-rename




  public get OrderStatus(): typeof OrderStatus {
    return OrderStatus;
  }

  ngOnChanges(): void {
    if (this.detailData != null) {
      if (!(this.uploads.find(element => element.patientID == this.detailData.patientID))) {
        this.detailData = null;

      } else {
        this.detailData = this.uploads.find(element => element.patientID == this.detailData.patientID);
      }

    } else {
      if (this.uploads.length > 0) {
        this.detailData = this.uploads[0];
      }
    }
  }

  selected;
  detailData;
  uploadModal = false;
  values: string[] = ['3D-Scan','Vermessung','Unterlagen','Kostenvoranschlag','Auftrag']
  notesFormControl;
  typeFormControl;

  // Main task
  task: AngularFireUploadTask;

// Progress monitoring
  percentage: Observable<number>;

  snapshot: Observable<any>;

// Download URL
  downloadURL: Observable<string>;

  selectedFiles: FileList;


  detectFiles(event) {
    this.selectedFiles = event.target.files;
  }

  selectNewest() {
    this.detailData = this.uploads[0];
  }

  constructor(

    private iosService: IosUploadService,
    private storage: AngularFireStorage,
    private db: AngularFirestore,
    private formBuilder: FormBuilder,
  ) {
    this.uploadForm = this.formBuilder.group({
      notes: this.notesFormControl,
      type: this.typeFormControl,
    });
  }

  uploadForm: FormGroup;
  formGroup: FormGroup;



  openDetails(patient) {
    this.detailData = patient;
  }
  openUploadModal(patient) {
    this.detailData = patient;
    this.uploadModal = true;
  }

  deletePatient(patient) {
    if (this.uploads.length == 0) {
      this.detailData = null;
    } else {
      this.detailData = this.uploads[0];
    }
    this.iosService.deletePatient(patient).then();
  }

  closeDetails() {
    this.detailData = null;
  }

  checkDetails(data): boolean {
    if (data != null && this.detailData != null) {
      if (data.patientID == this.detailData.patientID) {
        return true;
      }
    }
    return false;
  }

  setValue(v): void {
    this.uploadForm.get('type').setValue(v);
  }

  async createPatientUpload(patient): Promise<void> {
    const onCreate = Date.now();
    const uploadType = this.uploadForm.get('type').value;
    const dentistNotes = this.uploadForm.get('notes').value;
    const labNotes = null;
    const downloaded = false;
    const uploads = this.detailData.uploads;
    const file = this.selectedFiles.item(0);


    const filename = `${file.name}`;


    // The main task
    this.task = this.storage.upload(filename, file,);

    // Reference to storage bucket
    const ref = this.storage.ref(filename);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges();

    this.snapshot = this.task.snapshotChanges().pipe(
      tap(console.log),

      // The file's download URL
      finalize(async () => {
        this.downloadURL = await ref.getDownloadURL().toPromise();
        uploads.push({uploadType, onCreate, labNotes, dentistNotes, downloaded, filename, url: this.downloadURL});

        await this.db.collection('iosScans').doc(patient.patientID).set(
          {uploads},
          {merge: true}
        );

        this.uploadModal = false;
        this.selectedFiles = null;
        this.downloadURL = null;
        this.percentage = null;
        this.snapshot = null;
        this.uploadForm.reset();

      })
    );


  }


}

export enum OrderStatus {
  NEW,
  PENDING,
  COMPLETE,
  ARCHIVE
}

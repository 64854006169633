import {Component, OnInit} from '@angular/core';
import {AngularFireStorage, AngularFireUploadTask} from '@angular/fire/storage';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {AuthService} from 'src/app/services/auth.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {EmailService} from 'src/app/services/email.service';
import {FirebaseService} from "../../services/firebase.service";


interface Materialandcolor {
  material: string;
}

@Component({
  selector: 'app-newuploadform',
  templateUrl: './newuploadform.component.html',
  styleUrls: ['./newuploadform.component.scss']
})
export class NewuploadformComponent implements OnInit {
  isOpen = true;

  negativdate2;

  formGroup: FormGroup;
  descFormControl: FormControl;
  einheitenFormControl: FormControl;
  materialFormControl: FormControl;
  farbeFormControl: FormControl;
  lieferdatumFormControl: FormControl;
  wasFormControl: FormControl;
  notesFormControl: FormControl;
  typeFormControl: FormControl;
  brandFormControl: FormControl;
  patnameFormControl: FormControl;
  patlastnameFormControl: FormControl;
  technicanFormControl: FormControl;

  public options: string;
  public options2: string;


  materialbrandcolorCol: AngularFirestoreCollection<Materialandcolor>;
  materialbrandcolor: Observable<Materialandcolor[]>;
  materialtypeCollection: AngularFirestoreCollection<Materialandcolor>;
  materiatype: Observable<Materialandcolor[]>;
  materialbrandCollection: AngularFirestoreCollection<Materialandcolor>;
  materialbrand: Observable<Materialandcolor[]>;

// Main task
  task: AngularFireUploadTask;

// Progress monitoring
  percentage: Observable<number>;

  snapshot: Observable<any>;

// Download URL
  downloadURL: Observable<string>;

  selectedFiles: FileList;


  constructor(
    private storage: AngularFireStorage,
    private firebaseService: FirebaseService,
    private db: AngularFirestore,
    public authService: AuthService,
    private formBuilder: FormBuilder,
    public emailService: EmailService,
  ) {
    this.descFormControl = new FormControl('', Validators.required);
    this.einheitenFormControl = new FormControl('', Validators.required);
    this.materialFormControl = new FormControl('');
    this.farbeFormControl = new FormControl('');
    this.lieferdatumFormControl = new FormControl('');
    this.wasFormControl = new FormControl('');
    this.notesFormControl = new FormControl('');
    this.typeFormControl = new FormControl('', Validators.required);
    this.brandFormControl = new FormControl('', Validators.required);
    this.patnameFormControl = new FormControl('');
    this.patlastnameFormControl = new FormControl('');
    this.technicanFormControl = new FormControl('');

    this.formGroup = this.formBuilder.group({
      desc: this.descFormControl,
      ordernumber: this.einheitenFormControl,
      materialorder: this.materialFormControl,
      teethcolor: this.farbeFormControl,
      deliverydate: this.lieferdatumFormControl,
      whattosend: this.wasFormControl,
      notes: this.notesFormControl,
      materialtype: this.typeFormControl,
      brand: this.brandFormControl,
      patname: this.patnameFormControl,
      patlastname: this.patlastnameFormControl,
      technican: this.technicanFormControl,
    });
  }


  detectFiles(event) {
    this.selectedFiles = event.target.files;
  }

  ngOnInit() {
    this.authService.getUser();
    this.getMaterialtypes();
  }


  setnegativdate() {
    const ms = this.formGroup.get('deliverydate').value;
    const a = ms.split('.');
    const day = 100 - (+a[0]);
    const month = 100 - (+a[1]);
    const year = 10000 - (+a[2]);
    const d = day.toString()
    const m = month.toString()
    const y = year.toString()
    this.negativdate2 = (y + m + d)
    console.log(day);
    console.log(month);
    console.log(year);
    console.log(this.negativdate2);
  }


  async sendEmail(): Promise<void> {
    console.log('ist im new email service');
    const companyName: string = this.authService.singleuser.companyname;
    const description: string = this.formGroup.get('desc').value;
    const technicians: string = this.formGroup.get('technican').value;
    const orderNumber: string = this.formGroup.get('ordernumber').value;
    const messageText: string = `${companyName} hat ${orderNumber} Einheiten gesendet!`;
    const text = `Beschreibung ${description} Techniker ${technicians}`;
    await this.firebaseService.sendMailToMillingCenter({
      to: 'cadcam@zt-sauerland.de',
      message: {
        subject: messageText,
        html: text
      }
    })
  }

  startUpload(): void {
    if (!this.formGroup.valid) {
      return;
    }

    this.sendEmail().then()

    this.setnegativdate();
    // The File object
    const file = this.selectedFiles.item(0);

    // Client-side validation example

    const date = `${Date.now()}`;
    const ordernumber = this.formGroup.get('ordernumber').value;
    const whattosend = this.formGroup.get('whattosend').value;
    const deliverydate = this.formGroup.get('deliverydate').value;
    const description = this.formGroup.get('desc').value;
    const teethcolor = this.formGroup.get('teethcolor').value;
    const notes = this.formGroup.get('notes').value;
    const status = 1;
    const companyname = this.authService.singleuser.companyname;
    const UserID = this.authService.singleuser.uid;
    const materialbrand = this.formGroup.get('brand').value;
    const materialtype = this.formGroup.get('materialtype').value;
    const patname = this.formGroup.get('patname').value;
    const patlastname = this.formGroup.get('patlastname').value;
    const technican = this.formGroup.get('technican').value;
    const negativdate = this.negativdate2;

    // The storage path
    const path = `${file.name}`;


    // Totally optional metadata
    const customMetadata = {app: 'My AngularFire-powered PWA!'};

    // The main task
    this.task = this.storage.upload(path, file, {customMetadata});

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges();

    this.snapshot = this.task.snapshotChanges().pipe(
      tap(console.log),

      // The file's download URL
      finalize(async () => {
        this.downloadURL = await ref.getDownloadURL().toPromise();

        await this.db.collection('files').add({
          downloadURL: this.downloadURL, path, companyname,
          UserID, date, whattosend, status, ordernumber, description, teethcolor, notes,
          deliverydate, materialbrand, materialtype, patname, patlastname, technican, negativdate
        });
      }),
    );

  }

// Determines if the upload task is active
  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

  getMaterialtypes() {
    this.materialtypeCollection = this.db.collection('materialtypes');
    this.materiatype = this.materialtypeCollection.valueChanges();
  }


  getMaterialbrands() {

    const materialtype = this.formGroup.get('materialtype').value;

    this.materialbrandCollection = this.db.collection('materialbrands', ref => {
      return ref.where('typebrandkey', '==', materialtype);
    });
    this.materialbrand = this.materialbrandCollection.valueChanges();
  }

  getMaterialcolors() {

    const materialbrand = this.formGroup.get('brand').value;

    this.materialbrandcolorCol = this.db.collection('materialcolors', ref => {
      return ref.where('brand', '==', materialbrand);
    });
    this.materialbrandcolor = this.materialbrandcolorCol.valueChanges();
  }

  getOptions() {
    this.getMaterialbrands();
  }

  getOptions2() {
    this.getMaterialcolors();
  }

}

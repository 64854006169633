import { Injectable, NgZone } from '@angular/core';
import { Userdetails } from './user_details';
import { User } from 'src/app/services/user';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';

interface Uploads {
  UserID: any;
  companyname: any;
  date: Date;
  path: string;
  status: string;
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  name: any;
  userdetaildata = 'Schmalle';
  UserCollection: any;
  Usergroup: any;

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone,
    // NgZone service to remove outside scope warning

  ) {

    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        this.userData = JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        this.userData = JSON.parse(localStorage.getItem('user'));
      }
    });
  }
  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false) ? true : false;
  }
  userData: User; // Save logged in user data
  firestore: any;
  userdetails: any;
  singleuser: any;

  // Sign in with email/password
  SignIn(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        });
        this.UpdateUserData(result.user);
      }).catch((error) => {
        console.log(error.message);
      });
  }

  // Sign up with email/password
  SignUp(email: string, password: string , userdetails: Userdetails) {
    this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        this.SendVerificationMail();
        this.SetUserData(result.user, userdetails);
        // this.updateUserdata(result.user.uid);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }


  updateUserdata(uid, companyname) {
    return this.afs.collection('users').doc(uid).set({companyname: companyname}, { merge: true});
 }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification()
    .then(() => {
      this.router.navigate(['verify-email-address']);
    });
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      window.alert('Passwortreset E-Mail ist versendet, bitte schauen Sie in Ihrem Postfach nach');
    }).catch((error) => {
      console.log(error);
    });
  }

  SetUserData(user: User, userdetails: Userdetails) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    return userRef.set({
      uid: user.uid,
      email: user.email,
      companyname: userdetails.companyname,
      postcode: userdetails.postcode,
      city: userdetails.city,
      companytype: userdetails.companytype,
      street: userdetails.street,
      technican: userdetails.technican,
      phone: userdetails.phone,
      AGB: userdetails.AGB,

    }, {
      merge: true
    });
  }

  UpdateUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    };
    return userRef.set(userData, {
      merge: true
    });
  }
  // Sign out
  SignOut() {
    return this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['sign-in']);
    });
  }

  getSingleUser2() {
    return this.afs.collection('users').doc(this.getTestStringUid()).get();
  }

  getSingleUser() {
    return this.afs.collection('users').doc(this.getTestStringUid()).get();
  }

  async getUser() {
   const doc = await this.getSingleUser2().toPromise()
    this.singleuser = doc.data()
    return doc.data()
  }

  getTestStringUid(): string {
    const user: User = JSON.parse(localStorage.getItem('user'))
     if (user) {
        return user.uid
     } else {
       return 'no uid'
     }
  }

  getUserUploads() {
    return this.afs.collection('files/').ref.orderBy('UserID');
  }
  getuserId() {

  }
}

export class Userdetails {
   uid: string;
   email: string;
   companyname: string;
   postcode: string;
   city: string;
   companytype: string;
   street: string;
   technican: string;
   phone: string;
   AGB: string;
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private firestore: AngularFirestore
  ) { }

  neworderMail() {
    console.log('ist im new email service');
    this.firestore.collection('mail').add({
      to: 'dwinkelmann@outlook.com',
      message: {
        subject: 'Hello from Firebase!',
        html: 'This is an <code>HTML</code> email body.',
      },
    })
  }
}

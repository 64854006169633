<div class="clr-row">
  <div class="clr-col-5">
    <clr-datagrid>
      <clr-dg-column>Patient</clr-dg-column>
      <clr-dg-column>Erstellungsdatum</clr-dg-column>
      <clr-dg-column>Upload</clr-dg-column>
      <clr-dg-column>Details</clr-dg-column>

      <clr-dg-row (click)="openDetails(upload)" *clrDgItems="let upload of uploads" [clrDgItem]="upload">


        <clr-dg-cell>{{upload.patientName}} {{upload.patientLastname}}</clr-dg-cell>
        <clr-dg-cell>{{upload.onCreate | date:'medium'}}</clr-dg-cell>

        <clr-dg-cell>
          <button class="btn btn-outline-success btn-sm"
            (click)="openUploadModal(upload)">Daten hochladen
          </button>
        </clr-dg-cell>
        <clr-dg-cell>
          <button [ngClass]="(checkDetails(upload)) ? 'btn btn-primary btn-sm' : 'btn btn-sm'"
                  (click)="openDetails(upload)">Details
          </button>
        </clr-dg-cell>

      </clr-dg-row>


    </clr-datagrid>


  </div>

  <div *ngIf="detailData" class="clr-col-7">
    <h2>{{detailData.patientName}} {{detailData.patientLastname}}</h2>
    <div>
      <button (click)="openUploadModal(detailData)" class="btn btn-success">Neue Daten hochladen</button>
      <button (click)="deletePatient(detailData)" class="btn btn-danger">Patient Löschen</button>
    </div>

    <table class="table">
      <thead>
      <tr>
        <th>Art</th>
        <th>Upload-Datum</th>
        <th>Anmerkung</th>
        <th>Datei-Name</th>
        <th>Download</th>
        <th>Löschen</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of detailData.uploads">
        <td>{{item['uploadType']}}</td>
        <td>{{item['onCreate'] | date:'medium'  }}</td>
        <td>
          <a *ngIf="(item['dentistNotes'] != null)" role="tooltip" aria-haspopup="true"
             class="tooltip tooltip-right">
            <clr-icon shape="info-circle" size="24"></clr-icon>
            <span class="tooltip-content">{{item['dentistNotes']}}</span>
          </a>
        </td>
        <td>{{item['filename']}}</td>


        <td>
          <span *ngIf="(item['downloaded'] == false)" class="label label-info">Noch nicht heruntergeladen</span>
          <span *ngIf="(item['downloadDate'] != null)" class="label label-success">
                    Download am: {{ item['downloadDate'] | date:'medium' }}</span>
        </td>
        <td>
          <clr-icon shape="trash" (click)="iosService.removePatientUpload(detailData,item)" class="is-solid" size="24"></clr-icon>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


<clr-modal *ngIf="detailData" [(clrModalOpen)]="uploadModal">
  <h3 class="modal-title">Daten Upload für {{detailData.patientName}}</h3>
  <div class="modal-body">
    <input type="file" (change)="detectFiles($event)">
    <form clrForm [formGroup]="uploadForm">


      <clr-input-container>
        <label>Was wird hochgeladen?</label>
        <input formControlName="type" clrInput placeholder="z.B 3D-Scans" class="formControl" name="type"/>
      </clr-input-container>
      <button *ngFor="let item of values" class="btn btn-sm" (click)="setValue(item)">{{item}}</button>
      <clr-textarea-container>
        <label>Notizen</label>
        <textarea clrTextarea formControlName='notes' class="formControl" name="description"></textarea>
      </clr-textarea-container>
    </form>
  </div>
  <div class="modal-footer">
    <div class="progress" *ngIf="percentage | async as pct" class="progress labeled">
      <progress [value]="pct" max="100" data-displayval="0%"></progress>
      {{ pct | number }}%
    </div>
    <div *ngIf="snapshot | async as snap">
      {{ snap.bytesTransferred | fileSize }} of {{ snap.totalBytes | fileSize }}
    </div>
    <button type="button" class="btn btn-outline" (click)="uploadModal = false">Abbrechen</button>
    <button type="button" class="btn btn-primary" (click)="createPatientUpload(detailData)">Upload</button>
  </div>
</clr-modal>

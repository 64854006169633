<clr-main-container class="form-bg">
  <app-header></app-header>
  <div class="content-container">
    <main class="content-area">
      <div class="clr-row">
        <div class="clr-col-sm-12 clr-col-md-6">
          <div *ngIf="authService.singleuser as singleuser">
            <h1>Neuer Fräsauftrag:</h1>
            <form clrForm [formGroup]="formGroup">
              <clr-input-container>
                <label>Patientenname</label>
                <input formControlName="patname" clrInput placeholder="Vorname" class="formControl" name="name" />
              </clr-input-container>
              <clr-input-container>
                  <input formControlName="patlastname" clrInput placeholder="Nachname" class="formControl" name="name" />
                </clr-input-container>
              <clr-input-container>
                <label>Ansprechpartner</label>
                <input formControlName="technican" clrInput placeholder="Ansprechpartner" class="formControl"
                  name="name" />
              </clr-input-container>
              <clr-input-container>
                <label>Beschreibung</label>
                <input formControlName="desc" clrInput placeholder="Beschreibung" class="formControl" name="name" />
                <clr-control-helper>z.B. NEM Brücke von 34-36</clr-control-helper>
              </clr-input-container>
              <clr-input-container>
                <label>Anzahl Einheiten</label>
                <input formControlName='ordernumber' type="number" class="formControl" clrInput />
              </clr-input-container>
              <clr-select-container>
                <label>Material</label>
                <select formControlName="materialtype" (ngModelChange)="getOptions($event)" clrSelect
                  class="formControl" name="options" required>
                  <option *ngFor="let data of materiatype | async" value="{{ data.name }}">{{ data.name }}</option>
                </select>
              </clr-select-container>
              <clr-select-container>
                <label>Materialmarke</label>
                <select formControlName="brand" (ngModelChange)="getOptions2($event)" clrSelect class="formControl"
                  name="options" required>
                  <option *ngFor="let data of materialbrand | async" value="{{ data.brandname }}">{{ data.brandname }}
                  </option>
                </select>
              </clr-select-container>
              <clr-select-container *ngFor="let data of materialbrandcolor | async">
                <label>Zahnfarbe</label>
                <select formControlName='teethcolor' class="formControl" clrSelect name="options" required>
                  <option *ngFor="let data of data.color" value="{{ data }}">{{ data }}</option>
                </select>
              </clr-select-container>
              <clr-date-container>
                <label>Lieferdatum</label>
                <input formControlName='deliverydate' class="formControl" type="date" name="date" clrDate>
              </clr-date-container>
              <clr-select-container>
                <label>Was wird gesendet?</label>
                <select formControlName='whattosend' class="formControl" clrSelect name="options" required>
                  <option value="Datensatz">Datensatz</option>
                  <option value="Interoralscan">Interoralscan</option>
                  <option value="gescannte Modelle">gescannte Modelle</option>
                  <option value="loeffel">Modelle für Löffelkonstruktion</option>
                  <option value="andere">andere</option>
                </select>
              </clr-select-container>
              <clr-textarea-container>
                <label>Notizen</label>
                <textarea clrTextarea formControlName='notes' class="formControl" name="description"></textarea>
              </clr-textarea-container>
              <br>
              <br>
              <input type="file" (change)="detectFiles($event)">
              <br>
              <br>
              <button [disabled]="!formGroup.valid" (click)="startUpload()" type="submit"
                class="btn btn-primary">Upload</button>
            </form>
            <div *ngIf="percentage | async as pct" class="progress labeled">
              <progress [value]="pct" max="100" data-displayval="0%"></progress>
              {{ pct | number }}%
            </div>
            <div *ngIf="snapshot | async as snap">
              {{ snap.bytesTransferred | fileSize }} of {{ snap.totalBytes | fileSize }}
            </div>
            <div *ngIf="downloadURL as url">
              <clr-modal [(clrModalOpen)]="isOpen">
                <h3 class="modal-title">Upload erfolgreich!</h3>
                <div class="modal-body">
                  <p>Vielen Dank für Ihr Vertrauen!</p>
                </div>
                <div class="modal-footer">
                  <a [routerLink]="'/orders'" type="button" class="btn btn-outline">zu den Aufträgen</a>
                  <button type="button" class="btn btn-primary" (click)="isOpen = !isOpen">Ok</button>
                </div>
              </clr-modal>
            </div>

          </div>
        </div>
      </div>


    </main>
  </div >

</clr-main-container>

<div class="login-wrapper">
    <form class="login">
        <section class="title">
            <h3 class="welcome">Vielen Dank für die Registrierung bei</h3>
            <br>
            Dentaltechnik Hamm
            <br>
            <h5 *ngIf="authService.userData as user" class="hint">Wir haben eine Bestätigunsemail an <strong>{{user.email}}</strong> gesendet</h5>
            <br>
        </section>
            <button (click)="authService.SendVerificationMail()" type="submit" class="btn btn-primary">Bestätigunsmail erneut senden</button>
            <br>
            <a routerLink="/sign-in" class="signup">zurück zur Anmeldung</a>
    </form>
</div>
  
<clr-header class="header-5">
        <div class="branding">
            <a [routerLink]="'/dashboard'" class="nav-link">
                <clr-icon shape="envelope"></clr-icon>
                <span class="title">Dental-Technik Hamm</span>
            </a>
        </div>
        <div class="header-nav" [clr-nav-level]="1">
                <a [routerLink]="'/newupload'" routerLinkActive="active" class="nav-link"><span class="nav-text">Fräsauftrag</span></a>

                <a [routerLink]="'/orders'" routerLinkActive="active" class="nav-link"><span class="nav-text">Fräs-Aufträge</span></a>
                <a [routerLink]="'/archiv'" routerLinkActive="active" class="nav-link"><span class="nav-text">Fräs-Archiv</span></a>
          <a [routerLink]="'/iosscan'" routerLinkActive="active" class="nav-link"><span class="nav-text">Intraoralscan-Aufträge und Archiv</span></a>

            </div>
        <div class="header-actions">
            <clr-dropdown>
                <button clrDropdownTrigger>
                    <clr-icon shape="user" size="24"></clr-icon>
                </button>
                <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                    <a (click)="authService.SignOut()" clrDropdownItem>Abmelden</a>
                </clr-dropdown-menu>
            </clr-dropdown>
        </div>
    </clr-header>

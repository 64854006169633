import {Injectable} from '@angular/core';
import {AngularFireStorage} from "@angular/fire/storage";
import {AngularFirestore} from "@angular/fire/firestore";
import {AuthService} from "./services/auth.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {EmailService} from "./services/email.service";
import {AngularFireAuth} from "@angular/fire/auth";
import * as uuid from 'uuid';
import {OrderStatus} from "./iosupload/iosupload.component";

@Injectable({
  providedIn: 'root'
})
export class IosUploadService {
  private uploadForm: FormGroup;
  private notesFormControl: string;
  private typeFormControl: string;

  constructor(
    private storage: AngularFireStorage,
    private db: AngularFirestore,
    public authService: AuthService,
    private formBuilder: FormBuilder,
  ) {

  }

  async deletePatient(patient): Promise<void> {
    await this.db.collection('iosScans').doc(patient.patientID).delete();
  }

  async archivPatient(patient): Promise<void> {
    await this.db.collection('iosScans').doc(patient.patientID).update({
        status: OrderStatus.ARCHIVE
      }
    );
  }

  async removePatientUpload(patient, uploadItem): Promise<void> {
    const uploads = patient.uploads;

    const index = uploads.findIndex(item => item.onCreate == uploadItem.onCreate);

    uploads.splice(index, 1);

    await this.db.collection('iosScans').doc(patient.patientID).set(
      {uploads},
      {merge: true}
    );
  }


  async createPatient(name: string, lastName: string): Promise<void> {
    const patientID = uuid.v4();
    const onCreate = `${Date.now()}`;
    const status = 1;
    const dentist = this.authService.singleuser.companyname
    const UserID = this.authService.singleuser.uid;
    const patientName = name;
    const patientLastname = lastName;
    const uploads = [];

    await this.db.collection('iosScans').doc(patientID).set({
      patientID, onCreate, dentist, UserID, status, patientName, patientLastname, uploads
    });
  }
}



import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';

@Component({
  selector: 'app-archiv',
  templateUrl: './archiv.component.html',
  styleUrls: ['./archiv.component.scss']
})
export class ArchivComponent implements OnInit {

  files;

  constructor(
    private firebaseService: FirebaseService
  ) {
  }

  ngOnInit() {
    this.getUserId();
    this.getUploads();
  }

  getUserId() {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user.uid);
  }

  getUploads = () =>
  this.firebaseService
  .getArchiv()
  .subscribe(res => (this.files = res))

}

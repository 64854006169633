import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import {MailContent} from "../models/mail.interface";

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    private firestore: AngularFirestore) { }
  form = new FormGroup({
    blankName: new FormControl(''),
    cargeNumber: new FormControl(''),
    testdate: new FormControl(''),
    material: new FormControl(''),
    werkstoffName: new FormControl(''),
    shortName: new FormControl(''),
});
createDentalmaterial(data) {
  return new Promise<any>((resolve, reject) => {
    this.firestore
      .collection('dentalmaterial')
      .add(data)
      .then(res => {}, err => reject(err));
  });
}
createRohlinge(data) {
  return new Promise<any>((resolve, reject) => {
    this.firestore
      .collection('rohlinge')
      .add(data)
      .then(res => {}, err => reject(err));
  });
}
getRohlinge() {
  return this.firestore.collection('rohlinge').snapshotChanges();
}

getTitanRohlinge2() {
  this.firestore.collection('rohlinge');
}


getDentalmaterial() {
  return this.firestore.collection('dentalmaterial').snapshotChanges();
}

getUsers() {
  return this.firestore.collection('users').snapshotChanges();
}

getUploads() {
  return this.firestore.collection('files', ref => {
      return ref.where('UserID', '==', this.getUserId()).where('status', '==', 10);
    }).snapshotChanges();
}

getArchiv() {
  return this.firestore.collection('files', ref => {
      return ref.orderBy('date').where('UserID', '==', this.getUserId()).where('archiv', '==', true);
    }).snapshotChanges();
}

getUserId() {
  const user = JSON.parse(localStorage.getItem('user'));
  return (user.uid);
}

deleteRohlinge(data) {
  return this.firestore
    .collection('rohlinge')
    .doc(data.payload.doc.id)
    .delete();
}

deleteUsers(data) {
  return this.firestore
    .collection('users')
    .doc(data.payload.doc.id)
    .delete();
}
deletematerial(data) {
  return this.firestore
    .collection('dentalmaterial')
    .doc(data.payload.doc.id)
    .delete();
}
deleteUploads(data) {
  return this.firestore
    .collection('files')
    .doc(data.payload.doc.id)
    .delete();
}

updateOrder(data) {
    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ archiv: true }, { merge: true});

}

async sendMailToMillingCenter(data: MailContent): Promise<void> {
  console.log('mail send!')
  await this.firestore.collection('mail').add(data)
}

}

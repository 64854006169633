import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-uploadform',
  templateUrl: './uploadform.component.html',
  styleUrls: ['./uploadform.component.scss']
})
export class UploadformComponent implements OnInit {

  constructor(
    public authService: AuthService,
  ) { }

  ngOnInit() {
    this.authService.getUser();
  }

}

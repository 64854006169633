<clr-main-container>
    <app-header></app-header>
    <div class="content-container">
        <main class="content-area">
            <div>
                <h1>Herzlich Willkommen</h1>
                <br>
                <span>
                        Seit 1993 stehen wir mit hoher Handwerkskunst, in Verbindung mit modernsten Technologien, als <br>
                        kompetentes, serviceorientiertes Partnerlabor unseren Zahnarztpraxen zur Seite.<br>
                        <br>
                        Unsere Philosophie ist Teamwork zum Wohle des Patienten!<br>
                        <br>
                        Von der Einzelzahnkrone bis zu umfangreichen, hochwertigen Versorgungen liefern wir Gesamtkonzepte.<br>
                        <br>
                        „Mit Freude zu Ästhetik und Erfolg!“<br>
                        <br>
                        Sprechen Sie uns an!<br>
                        Ihr Team der Dental Technik V. Hamm<br>
                </span>
            </div>
          <div>
            <br>
            <h4>
              Wie können wir Ihnen helfen?
            </h4>
            <br>
            <button [routerLink]="'/iosscan'" class="btn btn-primary">INTRAORALSCAN HOCHLADEN</button><button [routerLink]="'/newupload'" class="btn btn-outline">FRÄS-DATENSATZ HOCHLADEN</button>
          </div>
        </main>
    </div>
</clr-main-container>

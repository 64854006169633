import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { FirebaseService } from 'src/app/services/firebase.service';

interface Uploads {
  UserID: any;
  companyname: any;
  date: Date;
  path: string;
  status: string;
}



@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})
export class OrdersListComponent implements OnInit {

  orderCollection: AngularFirestoreCollection<Uploads>;
  neworderCollection: AngularFirestoreCollection<Uploads>;
  finishorderCollection: AngularFirestoreCollection<Uploads>;
  order: Observable<Uploads[]>;
  neworder: Observable<Uploads[]>;
  finishorder: Observable<Uploads[]>;

  files;

  isOpen = false;

  constructor(
    private afs: AngularFirestore,
    public authService: AuthService,
    private firebaseService: FirebaseService
  ) { }

  ngOnInit() {
    this.getUserId();
    this.orderCollection = this.afs.collection('files', ref => {
      return ref.where('UserID', '==', this.getUserId()).where('status', '==', 1);
    });
    this.neworderCollection = this.afs.collection('files', ref => {
      return ref.where('UserID', '==', this.getUserId()).where('status', '<', 11).where('status', '>', 1);
    });
    this.finishorderCollection = this.afs.collection('files', ref => {
      return ref.where('UserID', '==', this.getUserId()).where('status', '>', 10).where('archiv', '==', false);
    });
    this.order = this.orderCollection.valueChanges();

    this.neworder = this.neworderCollection.valueChanges();

    this.finishorder = this.finishorderCollection.valueChanges();

    this.getUploads();

  }



getUserId() {
  const user = JSON.parse(localStorage.getItem('user'));
  return (user.uid);
}

markCompleted = data =>
this.firebaseService.updateOrder(data)


updateOrder(data) {
  return this.afs
      .collection('files')
      .doc(data.docID)
      .set({ archiv: true}, { merge: true});

}

getUploads = () =>
this.firebaseService
.getUploads()
.subscribe(res => (this.files = res))


updateStatusclick () {
  this.afs.collection('files').get().subscribe(querySnapshot => {
    querySnapshot.forEach(documentSnapshot => {
      console.log(`Dokument ID = ${documentSnapshot.id}`);
  });
  });

}
}
